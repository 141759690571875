<template>
  <div>
    <b-card >
      <b-row class="text-center">
        <b-col sm="12" md="3">
          <img
            width="25%"
            style="border-radius:50%;"
            src="@/assets/user_1.png"
            alt=""
          />

          <b-card-text>
            <p class="school32 text-center">Aбдулвоҳид Бурҳонов - Мунзим <br />(1875-1934)</p></b-card-text
          >
        </b-col>
        <b-col sm="12" md="6">
           <b-card-text>
          <p class="TextBlack17">
            Маърифатпарвар шоир, давлат ва жамоат арбоби Мирзо Aбдулвоҳид
            Бурҳонов 1875 йили Бухорода таваллуд топади. Дастлабки таълимни ески
            мактабда олади. ХИХ асрнинг 90-йилларидан Aбдулвоҳид Бурҳонов Мунзим
            тахаллуси билан маърифий шеърлар ёза бошлайди, кейинчалик еса амалий
            фаолиятида ҳам маърифатпарварлик билан шуғулланади. ХХ асрнинг
            биринчи ўн йиллигида Мунзим янги усулдаги мактаб очиш учун ҳаракат
            қила бошлайди. Бухородаги дастлабки жадид мактабининг очилиши ҳам
            унинг номи билан боғлиқ. У 1908 йили Самарқандга бориб, жадид
            мактабларидаги ўқитиш услубини Маҳмудхўжа Беҳбудий ва Aбдуқодир
            Шукурийдан ўрганади ва шу йили ўз ҳовлисида жадид мактаби, 1909
            йилда еса катта ёшдагилар учун кечки мактаб ташкил етади. Бухорода
            1909 йилдан шаклланган жадидлар ташкилоти аввал маърифатпарварлик
            ишларини олиб борди. Мунзим бу ташкилот ишига фаол қатнашиб, шу йили
            Мукаммил Бурҳонов, Садриддин Aйний билан биргаликда билан "Тарбияйи
            атфол" («Болалар тарбияси») махфий жамиятини тузишда иштирок етди.
            Бу жамият бухоролик маърифатпарварлар ва ҳур фикрли зиёлилар
            ўртасида илғор демократик ғояларни тарғиб етиб, халқнинг сиёсий
            онгини уйғотишда катта аҳамият касб этди. 1917 йил феврал
            инқилобидан сўнг Бухоро жадидлари A.Бурҳонов бошлиқ ески жадидларга,
            Ф.Хўжаев ва A.Фитрат бошлиқ янги жадидларга бўлинади. Биринчи
            гуруҳнинг асосий фаолияти маърифарпарварлик бўлса, иккинчи гуруҳ
            кенг ислоҳотлар тарафдори еди. Кейинчалик бу икки гуруҳнинг
            бирлашуви натижасида "Ёш бухороликлар" партияси тузилиб, Мунзим унга
            раис етиб сайланади.1918 йилдан 1920 йилнинг сентябригача Мунзим
            Тошкентда яшайди. Бухоро Халқ Совет Республикаси тузилгач, у аввал
            Марказий ижроия қўмитаси раисининг ўринбосари, кейинчалик Халқ
            маорифи нозири, Соғлиқни сақлаш нозири лавозимларида ишлайди. Халқ
            маорифи нозири лавозимида ишлаган Мунзим 1922 йил июнида миллий
            кадрлар, хусусан, муҳандислар тайёрлаш мақсадида бир гуруҳ бухоролик
            ёшларни Германияга юбориш тўғрисидаги БХСР ҳукумати қарорининг қабул
            қилинишига катта ҳисса қўшади. Шу қарорга асосан, у мазкур ишни
            ташкил етиш бўйича комиссия раиси етиб тайинланган еди. 1922 йилнинг
            ёзида Мунзимнинг шахсан ўзи бухоролик 44 кишини Германияга олиб
            бориб, Берлиндаги ўқув юртларига жойлаштиради. 1920 йилларнинг
            ўрталаридан бошлаб Мунзим ўзини тамоман ижодий ва публицистик
            фаолиятга бахшида етади. 30-й. бошида «Тожикистони сурх» газетасида
            ишлади. Aбдулвоҳид Мунзим 1934 йилда узоқ давом етмаган касалликдан
            сўнг Душанбеда вафот етади.
          </p>
        </b-card-text>
        </b-col>
      </b-row>
    </b-card>
    <!-- <b-card style="background-color:#EEEEEE">
      <b-container style="background-color:#EEE4CD;border-radius:20px">
       
      </b-container>
      <b-container>
        <b-row>
          <b-col>
            <b-button
              @click="$router.push({ name: 'jadidchilar_info' })"
              variant="outline-danger"
            >
              {{ $t("Back") }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-card> -->
  </div>
</template>
<script>
export default {};
</script>
<style></style>
